@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0 !important;
  width: 100%;
  overflow-x: hidden;
  background: #1d1e20;
  background-size: cover;
  scroll-behavior: smooth !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0;
}

.blur_section {
  background: url("./assets/icons/blur_top_content.svg") !important;
  background-position: top !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
}

.blue_green_gradient {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 6px;
}

.blue_green_gradient::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 4px;
  padding: 1px;
  /*control the border thickness */
  background: linear-gradient(
    to right top,
    rgba(1, 148, 255, 1),
    rgba(31, 248, 209, 1)
  );
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.blue_green_gradient_1 {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 6px;
}

.blue_green_gradient_1::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 10px;
  padding: 1px;
  /*control the border thickness */
  background: linear-gradient(
    to right top,
    rgba(1, 148, 255, 1),
    rgba(31, 248, 209, 1)
  );
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.right_gradient {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 6px;
}

.right_gradient::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 4px;
  padding: 1px;
  /*control the border thickness */
  background: linear-gradient(
    to right,
    rgba(1, 148, 255, 1),
    rgba(31, 248, 209, 1)
  );
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.box_transparent {
  position: relative;
  display: flex;
  flex-direction: row;
}

.box_transparent::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 16px;
  padding: 1.5px;
  /*control the border thickness */
  background: linear-gradient(to bottom, transparent, rgba(31, 248, 209, 1));
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

@media only screen and (max-width: 2560px) {
  .erase_section video {
    position: relative;
    top: 0% !important;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
  }

  .erase_section {
    position: relative;
    width: 100%;
    margin-top: -100px !important;
    height: 60vh;
  }
}

@media only screen and (max-width: 1920px) {
  .erase_section video {
    position: relative;
    top: 0% !important;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
  }

  .erase_section {
    position: relative;
    width: 100%;
    margin-top: -100px !important;
    height: 70vh;
  }
}

@media only screen and (max-width: 1440px) {
  .erase_section video {
    position: relative;
    top: 0% !important;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
    padding-top: 4rem;
  }

  .erase_section {
    position: relative;
    width: 100%;
    margin-top: -100px !important;
    height: 90vh;
  }
}

@media only screen and (max-width: 1280px) {
  .erase_section video {
    position: relative;
    top: 0% !important;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
    padding-top: 4rem;
  }

  .erase_section {
    position: relative;
    width: 100%;
    margin-top: -100px !important;
    height: 90vh;
  }
}

@media only screen and (max-width: 1024px) {
  .erase_section video {
    position: relative;
    top: 0% !important;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
    padding-top: 4rem;
  }

  .erase_section {
    position: relative;
    width: 100%;
    margin-top: -100px !important;
    height: 80vh;
  }
}

@media only screen and (max-width: 768px) {
  .erase_section video {
    position: relative;
    top: 0% !important;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
    padding-top: 4rem;
  }

  .erase_section {
    position: relative;
    width: 100%;
    margin-top: -100px !important;
    height: 80vh;
  }
}

@media only screen and (max-width: 320px) {
  .erase_section video {
    position: relative;
    top: 0% !important;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
  }

  .erase_section {
    position: relative;
    width: 100%;
    margin-top: -100px !important;
    height: 120vh;
  }
}

@media only screen and (max-width: 2560px) {
  .mask-image-radial-gradient {
    background-image: linear-gradient(
        to right,
        transparent,
        transparent,
        transparent,
        transparent,
        #1d1e20,
        #1d1e20
      ),
      linear-gradient(
        to left,
        transparent,
        transparent,
        transparent,
        transparent,
        #1d1e20,
        #1d1e20
      ),
      linear-gradient(
        to bottom right,
        transparent,
        transparent,
        transparent,
        transparent,
        #1d1e20,
        #1d1e20
      ),
      linear-gradient(
        to bottom left,
        transparent,
        transparent,
        transparent,
        transparent,
        #1d1e20,
        #1d1e20
      ),
      linear-gradient(
        to bottom,
        transparent,
        transparent,
        transparent,
        transparent,
        #1d1e20
      );
    width: 100%;
    height: 100%;
    background-size: contain !important;
    background-repeat: no-repeat;
    background-position: center;
  }
}

@media only screen and (max-width: 1920px) {
  .mask-image-radial-gradient {
    background-image: linear-gradient(
        to right,
        transparent,
        transparent,
        transparent,
        transparent,
        #1d1e20,
        #1d1e20
      ),
      linear-gradient(
        to left,
        transparent,
        transparent,
        transparent,
        transparent,
        #1d1e20,
        #1d1e20
      ),
      linear-gradient(
        to bottom right,
        transparent,
        transparent,
        transparent,
        transparent,
        #1d1e20,
        #1d1e20
      ),
      linear-gradient(
        to bottom left,
        transparent,
        transparent,
        transparent,
        transparent,
        #1d1e20,
        #1d1e20
      ),
      linear-gradient(
        to bottom,
        transparent,
        transparent,
        transparent,
        transparent,
        #1d1e20
      );
    width: 100%;
    height: 100%;
    background-size: contain !important;
    background-repeat: no-repeat;
    background-position: center;
  }
}

@media only screen and (max-width: 1440px) {
  .mask-image-radial-gradient {
    background-image: linear-gradient(
        to right,
        transparent,
        transparent,
        transparent,
        transparent,
        #1d1e20,
        #1d1e20
      ),
      linear-gradient(
        to left,
        transparent,
        transparent,
        transparent,
        transparent,
        #1d1e20,
        #1d1e20
      ),
      linear-gradient(
        to bottom right,
        transparent,
        transparent,
        transparent,
        transparent,
        #1d1e20,
        #1d1e20
      ),
      linear-gradient(
        to bottom left,
        transparent,
        transparent,
        transparent,
        transparent,
        #1d1e20,
        #1d1e20
      ),
      linear-gradient(
        to bottom,
        transparent,
        transparent,
        transparent,
        transparent,
        #1d1e20
      );
    width: 100%;
    height: 100%;
    background-size: contain !important;
    background-repeat: no-repeat;
    background-position: center;
  }
}

@media only screen and (max-width: 1280px) {
  .mask-image-radial-gradient {
    background-image: linear-gradient(
        to right,
        transparent,
        transparent,
        transparent,
        transparent,
        #1d1e20,
        #1d1e20
      ),
      linear-gradient(
        to left,
        transparent,
        transparent,
        transparent,
        transparent,
        #1d1e20,
        #1d1e20
      ),
      linear-gradient(
        to bottom right,
        transparent,
        transparent,
        transparent,
        transparent,
        #1d1e20,
        #1d1e20
      ),
      linear-gradient(
        to bottom left,
        transparent,
        transparent,
        transparent,
        transparent,
        #1d1e20,
        #1d1e20
      ),
      linear-gradient(
        to bottom,
        transparent,
        transparent,
        transparent,
        transparent,
        #1d1e20
      );
    width: 100%;
    height: 100%;
    background-size: contain !important;
    background-repeat: no-repeat;
    background-position: center;
  }
}

@media only screen and (max-width: 1024px) {
  .mask-image-radial-gradient {
    background-image: linear-gradient(
        to right,
        transparent,
        transparent,
        transparent,
        transparent,
        #1d1e20,
        #1d1e20
      ),
      linear-gradient(
        to left,
        transparent,
        transparent,
        transparent,
        transparent,
        #1d1e20,
        #1d1e20
      ),
      linear-gradient(
        to bottom right,
        transparent,
        transparent,
        transparent,
        transparent,
        #1d1e20,
        #1d1e20
      ),
      linear-gradient(
        to bottom left,
        transparent,
        transparent,
        transparent,
        transparent,
        #1d1e20,
        #1d1e20
      ),
      linear-gradient(
        to bottom,
        transparent,
        transparent,
        transparent,
        transparent,
        #1d1e20
      );
    width: 100%;
    height: 100%;
    background-size: contain !important;
    background-repeat: no-repeat;
    background-position: center;
  }
}

@media only screen and (max-width: 768px) {
  .mask-image-radial-gradient {
    background-image: linear-gradient(
        to right,
        transparent,
        transparent,
        transparent,
        transparent,
        transparent,
        transparent
      ),
      linear-gradient(
        to left,
        transparent,
        transparent,
        transparent,
        transparent,
        transparent,
        transparent
      ),
      linear-gradient(
        to bottom,
        transparent,
        transparent,
        transparent,
        transparent,
        #1d1e20
      );
    width: 100%;
    height: 100%;
    background-size: contain !important;
    background-repeat: no-repeat;
    background-position: center;
  }
}

@media only screen and (max-width: 425px) {
  .mask-image-radial-gradient {
    background-image: linear-gradient(
        to right,
        transparent,
        transparent,
        transparent,
        transparent,
        transparent,
        transparent
      ),
      linear-gradient(
        to left,
        transparent,
        transparent,
        transparent,
        transparent,
        transparent,
        transparent
      ),
      linear-gradient(
        to bottom,
        transparent,
        transparent,
        transparent,
        transparent,
        #1d1e20
      );
    width: 100%;
    height: 100%;
    background-size: contain !important;
    background-repeat: no-repeat;
    background-position: center;
  }
}

@media only screen and (max-width: 320px) {
  .mask-image-radial-gradient {
    background-image: linear-gradient(
        to right,
        transparent,
        transparent,
        transparent,
        transparent,
        transparent,
        transparent
      ),
      linear-gradient(
        to left,
        transparent,
        transparent,
        transparent,
        transparent,
        transparent,
        transparent
      ),
      linear-gradient(
        to bottom,
        transparent,
        transparent,
        transparent,
        transparent,
        #1d1e20
      );
    width: 100%;
    height: 100%;
    background-size: contain !important;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.button_gradient {
  border-radius: 4px;
  background: linear-gradient(
    90deg,
    rgba(79, 157, 255, 0.1) 0%,
    rgba(103, 255, 209, 0.1) 100%
  );
  border-width: 10px;
  border-image: linear-gradient(to right, #0194ff, #1ff8d1) 1;
  border-image-slice: 1;
}

.border-gradient {
  border-image: linear-gradient(to right, #4f9dff, #67ffd1) 1;
}

.team-gradient {
  position: relative;
  display: flex;
  flex-direction: row;
}

.team-gradient::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 50%;
  padding: 6px;
  /*control the border thickness */
  background: linear-gradient(
    to right,
    rgba(1, 148, 255, 1),
    rgba(31, 248, 209, 1)
  );
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.input_gradient {
  border-radius: 16px;
  border: 1px solid rgba(245, 247, 250, 0.06);
  background: linear-gradient(
    135deg,
    rgba(245, 247, 250, 0.06) 0%,
    rgba(245, 247, 250, 0) 100%
  );
  box-shadow:
    0px 4px 4px 0px rgba(9, 13, 20, 0.12),
    -2px -2px 4px 0px rgba(224, 224, 255, 0.02),
    0px 1px 2px 0px rgba(9, 13, 20, 0.24);
  backdrop-filter: blur(54.36563491821289px);
}

.background_contactus {
  background-image: url("./assets/icons/Ellipsecontactus.svg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  height: auto;
  width: auto;
  opacity: 25px;
}

.arrow-animation {
  background-image: url("./assets/icons/Down Left Arrow.svg"),
    url("./assets/icons/Down Left Arrow.svg");
  background-position-x: calc(100% - 20px), calc(100% - 40px);
  background-position-y:
    center,
    -100%;
  background-repeat: no-repeat, no-repeat;
  -webkit-transition: background-position 0.4s ease-in-out;
  -moz-transition: background-position 0.4s ease-in-out;
  -o-transition: background-position 0.4s ease-in-out;
  transition: background-position 0.4s ease-in-out;
}

.arrow-animation:hover {
  background-position-x: calc(100% + 40px), calc(100% - 20px);
  background-position-y: calc(100% + 40px), center;
  color: white;
}

.marquee-container {
  overflow: hidden;
  width: 100%;
}

.marquee {
  --gap: 1rem;
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
}

.marquee__content {
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: var(--gap);
  animation: scroll 30s linear infinite;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

@keyframes scroll-abs {
  from {
    transform: translateX(calc(100% + var(--gap)));
  }

  to {
    transform: translateX(0);
  }
}

/* Other page demo styles */
.marquee__content > * {
  flex: 0 0 auto;
  text-align: center;
}

/* .vignette {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 70%);
  pointer-events: none;
  z-index: 1;
} */
/*<div className="vignette"></div>*/

/* styles.css */
/* This is for WebKit-based browsers like Chrome and Safari */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 9999px;
}

/* This is for Firefox */
/* Note: In Firefox, scrollbar styling requires more complex CSS rules */
/* Make sure to use the -moz prefix for Firefox specific properties */
/* Here, we just make the scrollbar transparent to match the Chrome/Safari style */
/* You can customize this further if needed */
/* @-moz-document url-prefix() {
  scrollbar-color: transparent;
} */

/* styles.css */
.snap-x {
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  white-space: nowrap;
}

.snap-normal {
  scroll-snap-align: start;
  display: inline-block;
  /* margin-right: 16px; Optional: Adjust the spacing between snap points */
}

.mob_sticky {
  position: sticky !important;
  position: -webkit-sticky !important;
}

.navbar {
  color: white;
}

.navbar.colorChange {
  color: #67ffd1;
}

.navbar:hover {
  color: #67ffd1;
}

/* .kana_horizontal{
  will-change: transform;
  transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
} */

/* Gaming-Homepage */
.main-content {
  border-radius: var(--spacing-16, 16px);
  border: 1px solid rgba(245, 247, 250, 0.06);
  background: linear-gradient(
    135deg,
    rgba(245, 247, 250, 0.06) 0%,
    rgba(245, 247, 250, 0) 100%
  );
  box-shadow:
    0px 4px 4px 0px rgba(9, 13, 20, 0.12),
    -2px -2px 4px 0px rgba(224, 224, 255, 0.02),
    0px 1px 2px 0px rgba(9, 13, 20, 0.24);
  backdrop-filter: blur(54.36563491821289px);
}
.stats_container {
  border-radius: var(--spacing-16, 16px);
  border: 1px solid rgba(245, 247, 250, 0.06);
  background: linear-gradient(
    135deg,
    rgba(245, 247, 250, 0.06) 0%,
    rgba(245, 247, 250, 0) 100%
  );
  box-shadow:
    0px 4px 4px 0px rgba(9, 13, 20, 0.12),
    -2px -2px 4px 0px rgba(224, 224, 255, 0.02),
    0px 1px 2px 0px rgba(9, 13, 20, 0.24);
  backdrop-filter: blur(54.36563491821289px);
}
.blue_green_gradient_3 {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 6px;
}

.blue_green_gradient_3::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 4px;
  padding: 1px;
  /*control the border thickness */
  background: linear-gradient(
    to right top,
    rgba(1, 148, 255, 1),
    rgba(31, 248, 209, 1)
  );
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}
.blue_green_gradient_4 {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 6px;
}

.blue_green_gradient_4::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 16px;
  padding: 1px;
  /*control the border thickness */
  background: linear-gradient(
    to right top,
    rgba(1, 148, 255, 1),
    rgba(31, 248, 209, 1)
  );
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}
.blue_green_gradient_5 {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 6px;
}

.blue_green_gradient_5::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 16px;
  padding: 1px;
  /*control the border thickness */
  background: linear-gradient(
    to right top,
    rgba(1, 148, 255, 1),
    rgba(31, 248, 209, 1)
  );
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}
.ongoing_container {
  border-radius: var(--spacing-16, 16px);
  border: 1px solid rgba(245, 247, 250, 0.06);
  background: linear-gradient(
    135deg,
    rgba(245, 247, 250, 0.06) 0%,
    rgba(245, 247, 250, 0) 100%
  );
  box-shadow:
    0px 4px 4px 0px rgba(9, 13, 20, 0.12),
    -2px -2px 4px 0px rgba(224, 224, 255, 0.02),
    0px 1px 2px 0px rgba(9, 13, 20, 0.24);
  backdrop-filter: blur(54.36563491821289px);
}
.comingsoon_container {
  background: linear-gradient(120deg, #67ffd1 0%, #0194ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-family: Manrope;
}
.comingsoon_button {
  border-radius: 4px;
  background: linear-gradient(
    90deg,
    rgba(79, 157, 255, 0.1) 0%,
    rgba(103, 255, 209, 0.1) 100%
  );
  backdrop-filter: blur(12px);
}
.comingsoon_button_gradient {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 6px;
}

.comingsoon_button_gradient::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 4px;
  padding: 1px;
  /*control the border thickness */
  background: linear-gradient(
    to right top,
    rgba(1, 148, 255, 1),
    rgba(31, 248, 209, 1)
  );
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}
.toaster_message {
  border-radius: var(--spacing-16, 16px);
  border: 1px solid rgba(245, 247, 250, 0.06);
  background: linear-gradient(
    135deg,
    rgba(245, 247, 250, 0.06) 0%,
    rgba(245, 247, 250, 0) 100%
  );
  box-shadow:
    0px 4px 4px 0px rgba(9, 13, 20, 0.12),
    -2px -2px 4px 0px rgba(224, 224, 255, 0.02),
    0px 1px 2px 0px rgba(9, 13, 20, 0.24);
  backdrop-filter: blur(54.36563491821289px);
}

.custom-scrollbar::-webkit-scrollbar {
  width: 5px !important;
  height: 0.5px !important;
}
.custom-Scrollbar::-webkit-scrollbar-track {
  background-color: #00f9a9 !important;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-image: linear-gradient(wheat, white);
  border-radius: 36px !important;
  margin-right: 5px;
}

.bg_dashboard {
  background-image: url("./assets/icons/Basepixel2.svg");
}
.bg_dashboard2 {
  background-image: url("./assets/icons/Basepixel3.svg");
}
.bg_dashboard3{
  background-image: url("./assets/icons/Basepixel1.png");
}
.scroll_content {
  overscroll-behavior-y: auto !important;
}
.dropdown_bg-container {
  border-radius: var(--spacing-16, 16px);
  border: 1px solid rgba(245, 247, 250, 0.06);
  background: linear-gradient(
    135deg,
    rgba(245, 247, 250, 0.06) 0%,
    rgba(245, 247, 250, 0) 100%
  );
  box-shadow:
    0px 4px 4px 0px rgba(9, 13, 20, 0.12),
    -2px -2px 4px 0px rgba(224, 224, 255, 0.02),
    0px 1px 2px 0px rgba(9, 13, 20, 0.24);
  backdrop-filter: blur(54.36563491821289px);
}

.horizontal-scroll-container {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  width: 100%;
}

.horizontal-scroll-content {
  display: flex;
}

.vertical_scroll_container {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: y mandatory;
  width: 100%;
}
.vertical_scroll_content {
  display: flex;
}

.vertical_bg_container {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff00 23.96%,
    #ffffff00 77.6%,
    rgba(255, 255, 255, 0) 100%
  );
}
.vertical_transform {
  transform: (0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg)
    rotateZ(0deg) skew(0deg, 0deg);
}
